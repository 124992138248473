<template>
  <div
    class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="flex justify-center">
      <div
        class="bg-white lg:w-4/12 md:w-6/12 w-10/12 rounded-xl shadow-xl border"
      >
        <div class="py-8 px-8 rounded-xl">
          <img
            class="mx-auto w-auto"
            src="../assets/indomaret.png"
            alt="logo-idm"
          />

          <div>
            <h2 class="mt-6 text-center text-xl font-extrabold text-gray-900">
              E-learning Part Timer
            </h2>

            <p class="text-center text-sm text-gray-600">
              <a href="#" class="font-medium text-blue-600 hover:text-blue-500"
                >Store Crew</a
              >
            </p>
          </div>

          <form-group :validations="$v.phoneNumber">
            <div class="my-5 text-sm" slot-scope="{ errors }">
              <input
                autofocus
                v-model="phoneNumber"
                id="phone-number"
                :class="`px-4 py-3 mt-3 font-medium border-gray-200 focus:ring-blue-100 focus:border-blue-100 w-full rounded-md border-2 placeholder-gray-400 ${
                  $v.phoneNumber.$error ? 'border-error' : ''
                }`"
                placeholder="Nomor handphone i-saku"
                @blur="$v.phoneNumber.$touch"
                @keypress="onlyNumber"
              />
              <div
                v-for="(error, i) in errors"
                class="flex justify-start mt-1"
                :key="i"
              >
                <span class="text-invalid">{{ error }}</span>
                <!-- {{ $v.phoneNumber.$error ? "Nomor handphone wajib diisi." : "" }} -->
              </div>
            </div>
          </form-group>

          <div class="pt-3">
            <button
              :disabled="$v.phoneNumber.$error || isLoadingBtn"
              @click="handleLogin"
              type="submit"
              :class="`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-1 focus:ring-offset-1 ${
                $v.phoneNumber.$error || isLoadingBtn ? 'btn-disable' : ''
              }`"
            >
              <template v-if="!isLoadingBtn">
                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    class="h-5 w-5 text-blue-400 group-hover:text-blue-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                Masuk
              </template>

              <template v-else>
                <span class="p-3">
                  <loading
                    :active.sync="isLoadingBtn"
                    :can-cancel="false"
                    :is-full-page="fullPage"
                    :height="20"
                    :width="20"
                  ></loading>
                </span>
              </template>
            </button>
          </div>

          <div class="flex items-center justify-end mt-3 divide-y">
            <div class="text-xs space-x-0.5">
              Terkendala saat proses masuk?
              <a
                href="#"
                class="underline font-semibold font-medium text-blue-500 hover:text-blue-400"
                >klik untuk whatsapp</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  name: "Login",

  components: {
    FormGroup: () => import("@/components/FormGroup.vue"),
    Loading,
  },

  data() {
    return {
      count: 0,
      phoneNumber: null,
      loginStatus: null,
      disable: false,
      isLoadingBtn: false,
      fullPage: false,
    };
  },

  validations: {
    phoneNumber: { required },
  },

  // mounted() {
  //   this.$v.reset()
  // },

  methods: {
    ...mapActions(["login", "logout"]),

    handleLogin() {
      this.isLoadingBtn = true;
      this.login(this.phoneNumber)
        .then((res) => {
          const currentStep = res.participant.current_step;
          console.log(res);

          this.initialPage(currentStep);
          this.isLoadingBtn = false;
        })
        .catch((err) => {
          this.isLoadingBtn = false;
          console.log(err);
          this.logout();
          const errMsg = err.response.data.message;
          this.$swal("Oops...", errMsg, "error");
        });
    },

    initialPage(step) {
      console.log(step);

      if (step === "welcome") {
        this.$router.push({ name: "Home" });
      } else if (step === "guidelines") {
        this.$router.push({ name: "Guidelines" });
      } else if (Array("pre-test", "post-test", "remedial").includes(step)) {
        this.$router.push({ name: "Test", params: { typeTest: step } });
      } else if (step === "result") {
        this.$router.push({ name: "Result" });
      }
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
  },
};
</script>

<style>
.swal2-content {
  padding: 0px !important;
}
@media only screen and (min-width: 600px) {
  .swal2-content {
    padding: 0px 2.1rem !important;
  }
}
</style>
